<template>
    <div>
        <div class="leads-operations">
            <button @click.prevent="fetchProducts"><fa icon="sync-alt" /> Refresh</button>
        </div>
        <div class="leads-preview-options">
            <router-link to="/leads/carousel">Carousel</router-link>
            <router-link to="/leads/dashboard">Dashboard</router-link>
        </div>
        <router-view />
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'Leads',
    computed: {
        ...mapGetters({
            isProduction: 'ui/isProduction'
        })
    },
    methods: {
        ...mapActions({
            fetchProducts: 'leads/fetchProducts'
        })
    },
    beforeMount() {
        this.fetchProducts()
    },
    watch: {
        isProduction() {
            this.fetchProducts()
        }
    }
}
</script>
<style lang="scss" scoped>
.leads-operations {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    & button {
        font-size: small;
        font-family: $headline;
        text-decoration: none;
        display: inline-block;
        margin: 1em;
        background-color: $dark-indigo;
        cursor: pointer;
        color: $white;
        padding: .5em 1em;
        border-radius: 4px;
        border: none;
        outline: none;
        &:hover {
            color: rgba($white, .8)
        }
    }
}
.leads-preview-options {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
    & a {
        font-family: $headline;
        text-decoration: none;
        display: inline-block;
        margin: 1em;
        background-color: $dark-indigo;
        cursor: pointer;
        color: $white;
        padding: .5em 1em;
        border-radius: 4px;

        &:hover {
            color: rgba($white, .8)
        }

        &.router-link-exact-active {
            background-color: $orangey-red;
        }
    }
}
</style>
